import { Slider } from "./slider";
import logo from "../../assets/images/logo.jpg";
import { useNavigate } from "react-router-dom";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
// import Swal from "sweetalert2";

export const Landing = () => {
  const router = useNavigate();
  const toRoute = (route) => router(route);
  const [active, setActive] = useState(false);
  const [fullist, setFullist] = useState("default"); //true means show full list, false means show name and day or arrival

  const regRoute = () => {
    toRoute("/register");
  };

  // const sayHello = ()=>{
  // setFullist();
  // }
  useEffect(() => {
    console.log(fullist);
  }, [fullist]);

  return (
    // <div>
    //     <Slider />
    //     <p>
    //         Hello world
    //     </p>
    // </div>
    <div className="relative">
      <Slider {...regRoute} />
      <div className="absolute h-screen w-screen">
        <div className="flex py-6 px-6 lg:px-32 text-sm justify-between items-center">
          <img src={logo} alt="logo" className="h-12 rounded-md" />
          <div className="hidden lg:flex space-x-4">
            {/* <button className="btn-secondary">Protocol Login</button> */}
            {/* <button className="btn-secondary hover:bg-white hover:text-black" onClick={() => toRoute('/about')}>About</button> */}
            {/* <button className="btn-primary">Verify</button> */}
            <a href="https://forms.gle/vfji9YV9ic1ucrX5A" target="_blank">
              <button
                className="btn-secondary hover:bg-white hover:text-black"
                // onClick={() => regRoute()}
              >
                Register for ADWH
              </button>
            </a>
          </div>

          {active === true ? (
            <XIcon
              className="h-8 lg:hidden text-white"
              onClick={() => setActive(false)}
            />
          ) : (
            <MenuAlt2Icon
              className="h-8 lg:hidden text-white"
              onClick={() => setActive(true)}
            />
          )}
        </div>

        {/* mobile nav */}
        <div
          className={` z-20 lg:hidden fixed bg-white ${
            active === true ? "h-screen w-screen" : "h-0 w-0"
          }`}
        >
          <div
            className={`flex-col relative items-start space-y-5 pt-8 px-6 ${
              active === true ? "flex" : "hidden"
            }`}
          >
            <a href="https://forms.gle/vfji9YV9ic1ucrX5A" target="_blank">
              <button
                className="border px-4 py-2 rounded w-full"
                // onClick={() => regRoute()}
              >
                Register for ADWH
              </button>
            </a>
            {/* <button className="border px-4 py-2 rounded w-full" onClick={() => toRoute('/about')}>About</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
